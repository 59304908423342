window.web_message_manager = {

    generate_weight_restriction_msg(msg_box_id, new_total_net_weight, weight_restriction) {
        const total_net_weight = flt(new_total_net_weight, 2);
		const cart_weight_restriction = flt(weight_restriction, 2)
		
		$(msg_box_id).html('');
		if (total_net_weight > cart_weight_restriction) {
			let htmlContent = '<div class="weight-restriction-msg-icon-box over-weight-msg">';
			htmlContent += '<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">';
			htmlContent += '<path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="white" stroke-width="2"/>';
			htmlContent += '<path d="M11 6H11.01" stroke="white" stroke-width="2" stroke-linecap="round"/>';
			htmlContent += '<path d="M9 10H11V15M9 15H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>';
			htmlContent += '</svg>';
			htmlContent += '</div>';
			htmlContent += '<div class="weight-restriction-msg-container over-weight-msg">';
			htmlContent += `<p>Our max weight limit for Express and Next Day Delivery is ${cart_weight_restriction} lbs.</p>`;
			htmlContent += `<p>Your order's estimated weight: <span class="font-semibold-500">${total_net_weight}</span> lbs.</p>`;
			htmlContent += "</div>";
			$(msg_box_id).html(htmlContent);
			$(msg_box_id).addClass('display-msg');
		} else {
			$(msg_box_id).removeClass('display-msg');
		}
	},

	generate_large_order_warning_msg(msg_box_id) {
		$(msg_box_id).html('');
		let htmlContent = '<div class="large-order-msg-icon-box over-weight-msg">';
		htmlContent += '<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">';
		htmlContent += '<path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="white" stroke-width="2"/>';
		htmlContent += '<path d="M11 6H11.01" stroke="white" stroke-width="2" stroke-linecap="round"/>';
		htmlContent += '<path d="M9 10H11V15M9 15H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>';
		htmlContent += '</svg>';
		htmlContent += '</div>';
		htmlContent += '<div class="large-order-msg-container over-weight-msg">';
		htmlContent += `<p>Large online orders require a quick call with our service rep to confirm delivery details. Please call <a href="tel:+18842444426">1-884-244-4426</a> to complete your order.</p>`;
		htmlContent += "</div>";
		$(msg_box_id).html(htmlContent);
		$(msg_box_id).addClass('display-msg');
	}
}