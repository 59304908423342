

frappe.provide("bellamar.utils");
frappe.provide("bellamar.views");

frappe.ready(function() {
    let isHomepage = window.location.pathname === "/"
    let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
    if (isMobile && isHomepage) {
        /*open app from mobile browser*/
        var is_mobile = {
            Android: function() {
                return navigator.userAgent.match(/Android/i);
            },
            iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            }
        };
        var splash = $(`
        <div id="splash-modal" class="splash-modal">
            <div class="modal-content modal-content-background">
                <div class="modal-body modal-body-splash">
                    <div class="splash ht">
                        <div class="splash ht-60">
                            <div class="opacity-bg"></div>
                            <a href="" target="_blank"><img class="top-right" src="/assets/bellamar_website/splash/images/logo.png" width="128" height="55"></a>
                        </div>
                        <div class="splash ht-40 text-center">
                            <div>
                                <img class="mg-top" src="/assets/bellamar_website/splash/images/logo1.png" width="82" height="79">
                            </div>
                            <div class="stars">      
                                <i class="fa fa-star"></i>&nbsp;
                                <i class="fa fa-star"></i>&nbsp;
                                <i class="fa fa-star"></i>&nbsp;
                                <i class="fa fa-star"></i>&nbsp;
                                <i class="fa fa-star-half-o"></i>&nbsp;
                            </div>
                            <div class="center-align">
                                <div style="padding:10px 50px;">Download the Biiibo App and Use Coupon <strong>APPFIRST</strong> at checkout to <strong>save $35 on your first order</strong>!</div>
                            </div>
                            <div class="center-align">        		
                                <a class="btn btns btn-app"  href="javascript:void(0);">Continue in app</a>        
                            </div>
                            <div class="center-align">        		        
                                <a class="btns btn-browser"  href="javascript:void(0);">Continue in browser</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`)
        $(splash).appendTo("body");
        $('head').append('<link rel="stylesheet" type="text/css" href="/assets/bellamar_website/splash/css/splash.css">');
        if (frappe.get_cookie('mob_cache')) {
            $("#splash-modal").css('display', "none");
            $("#main-body").css("display","block");
        } else {
            setTimeout(function() {
                var splash_modal = document.getElementById("splash-modal");
                splash_modal.style.display = "block";
                $("#main-body").css("display","none");
            }, 100);
        }
        $(".btn-browser").click(function() {
            $("#splash-modal").css('display', "none");
            $("#main-body").css("display","block");
            frappe.call({
                method: "bellamar_website.utils.utils.set_cookie",
                args: {
                    cookie_name: "mob_cache",
                    value: "1",
                    days_valid: 30,
                }
            })
        });
        $(".btn-app").click(function() {
            //return to play store app
            if (is_mobile.Android()) {
                window.location.replace("https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.biiibo.app&ddl=1&pcampaignid=web_ddl_1");
            } else if (is_mobile.iOS()) {
                window.location.replace("https://apps.apple.com/in/app/biiibo/id1483782349");
            }
        });
    }
    load_lead_modal();
})

function load_lead_modal() {
    // Get the modal
    let leadModal = document.getElementById("leadModal");

    let leadEmailError = document.getElementById("leadInvalidEmail");

    // Get the <span> element that closes the modal
    let span = document.getElementsByClassName("close")[0];


    // When the user clicks on <span> (x), close the modal
    span.onclick = function() {
        leadModal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
        if (event.target === leadModal) {
            leadModal.style.display = "none";
        }
    }

    document.getElementById("submitLeadEmail").addEventListener("click", function() {
        const email = document.getElementById("leadEmailInput").value;
        if (validateEmail(email)) {
            frappe.callq({
                type: "POST",
                method: "biiibo_app.api.lead.connect_email_to_lead",
                args: {
                    email: email
                },
                callback: function (r) {
                    leadModal.style.display = "none";
                }
            });
        } else {
            leadEmailError.style.display = "block";
        }
    });

    document.getElementById("leadSignInButton").addEventListener("click", function() {
        window.location.href = "/login"
    });

    document.getElementById("leadSignUpButton").addEventListener("click", function() {
        window.location.href = "/login#signup"
    });
}

function show_lead_modal(cookie_name) {
    if (frappe.session.user === "Guest" && frappe.get_cookie("lead") && !frappe.get_cookie(cookie_name) && !frappe.get_cookie("lead_email")) {
        let leadModal = document.getElementById("leadModal");
        leadModal.style.display = "block";
        frappe.call({
            method: "bellamar_website.utils.utils.set_cookie",
            args: {
                cookie_name: cookie_name,
                value: "1",
                days_valid: 30,
            }
        });
    }
}

function validateEmail(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
}

frappe.provide("bellamar.utils");
frappe.provide("bellamar.views");
frappe.provide("bellamar.google_address");

bellamar.google_address.restriction = function(){
    // country wise restriction to google address
    let options = '';
    return frappe.get_cookie('city') === 'Seattle'?(options = {componentRestrictions: {country: "us"}}):(options = {componentRestrictions: {country: "ca"}});
}

bellamar.utils.optionJSON = function (self, newOptions, selectedOption) {
	var self 		= $(self);
	if(self.prop)
		var options = self.prop('options');
	else
		var options = self.attr('options');

	$('option', self).remove();
	$.each(newOptions, function(val, text) {
		options[options.length] = new Option(text, val);
	});
	self.val(selectedOption);
};

bellamar.utils.optionJSON2 = function (self, newOptions, selectedOption) {
	var self 		= $(self);
	if(self.prop)
		var options = self.prop('options');
	else
		var options = self.attr('options');

	$('option', self).remove();

	$.each(newOptions, function( i, newOption ) {
		// triming the length
		if (newOption.text.length > 25) {
			newOption.text = newOption.text.slice(0, 26);
			}
		//
		options[options.length] = new Option(newOption.text, newOption.val);
		//
	       $(`#cart_project option[value='${newOption.val}']`).prop('title', newOption.val);
	});
	self.val(selectedOption);
};

bellamar.utils.optionArray = function (self, newOptions, selectedOption) {
	var self 		= $(self);
	if(self.prop)
		var options = self.prop('options');
	else
		var options = self.attr('options');

	$('option', self).remove();
	if(options!=undefined){
		$.each(newOptions, function(index, value) {
			options[options.length] = new Option(value, value);
		});
		self.val(selectedOption);
	}
};

let category_tree_web_wrapper = $('#shop-by-nav-department-wrapper');
let category_tree_col3_web_wrapper = $('#dropdown-col-3');
let category_tree_mob_wrapper = $('#mob_menu_wrapper');

let callout_wrapper = $(".homepage-callout")
let resource_wrapper = $("#resource-content")
let explore_1_wrapper = $('#explore1-heading');
let explore_1_content_wrapper = $('#explore-content-1');
let explore_2_wrapper = $('#explore2-heading');
let explore_2_content_wrapper = $('#explore-content-2');

let explore_1_fields = ['Drywall Boards', 'Cement Boards', 'Drywall Tools', 'Drywall Tape', 'Corner Bead & Trim'];
let explore_1_heading = "Drywall";
let explore_2_fields
let explore_2_heading

let city = "";

frappe.ready(function() {
    if (!(frappe.get_cookie('guest_session'))) {
        generate_guest_uuid();
    }
    city = frappe.get_cookie('city');
    let param_city = frappe.utils.get_url_arg("city");
    if (param_city !== "" && param_city !== city) {
        change_city(param_city, false);
    } else if (!city) {
        change_city("Toronto", false);
    } else {
        load_brand_data();
    }
    
    if($("body[data-path='sale']").length) {
      load_recommended_products_for_sale()
    }
})

function change_city(ip_city, reload = false) {
    if (frappe.session.user && frappe.session.user !== "Guest") {
        frappe.callq({
            type: "POST",
            method: "bellamar_website.utils.utils.update_customer_city",
            freeze: true,
            args: {
                city: ip_city
            },
            callback: function(r) { 
                if(r?.message?.result === "success") {
                    if (reload) {
                        window.location.reload();
                    } else {
                        $('.selection-1').select2().val(ip_city).trigger('change');
                        city = ip_city;
                        if (city === "London") {
                            window.location.reload();
                        }
                        load_brand_data();
                    }
                }
            }
        })
    } else {
        frappe.call({
            method: "bellamar_website.utils.utils.set_cookie",
            args: {
                cookie_name: "city",
                value: "Toronto",
                days_valid: 30,
            },
            callback: function(r) {
                load_brand_data();
            }
        })
    }
}

function generate_guest_uuid() {
    frappe.call({
        method: "bellamar_website.utils.utils.generate_guest_uuid",
        callback: function(r) {
            set_guest_session(r)
        }
    })
}

function set_guest_session(uuid) {
    frappe.call({
        method: "bellamar_website.utils.utils.set_cookie",
        args: {
            cookie_name: "guest_session",
            value: uuid,
        }
    })
}

function load_brand_data() {
    frappe.call({
        method: "bellamar_website.utils.api.get_brands",
        args: {
            city: city,
        },
        callback:function(r){
            var brand_wrapper = $('#brand_menu_wrapper');
            var homepage_brand_wrapper = $('#homepage-brand-section');
            var limit = 0;
            if (r.message.length === 0) {
                $("#homepage-partner").hide();
                $('#brand-dropdown').hide();
            }
            $.each(r.message, function( index, node ) {
                limit += 1;
                // brands dropdown desktop: only show 20 brands
                if (limit < 21) {
                  var button_text = "";
                  if (node.image === null || node.image.indexOf(" ") > -1) {
                      button_text = node.value;
                  }
                  var link = window.location.origin + "/" + node.route
                  brand_wrapper.append(`<li class=dropdown-brand-button style="background-image: url(${node.image});"><a class=brand-link href="${link}">${button_text}</a></li>`);
                  if (homepage_brand_wrapper.length > 0) {
                      homepage_brand_wrapper.append(`
                      <div class="supplier-image-container">
                          <a href="${link}">
                              <img class="supplier-image" src="${node.image}">
                          </a>
                      </div>
                      `)
                  }
                }
                $('#mob_brand_menu_wrapper').append(`<li class="mob_brand_menu_list"><a class=mob_side_menu_brand-link href="/${node.route}">${node.value}</a></li>`);
                
            });
            if ($(".page-homepage").length > 0) {
                load_homepage();
                load_brand_carousel();
            } else if($(".recommended-products-notfoundpage").length > 0){ //404.html page
                load_category_tree();
            } else {
                load_category_tree();
            }
        }
    });
}

function load_recommended_products_for_sale() {
  frappe.call({
    method: "bellamar_website.utils.api.get_sales_page_data",
    args: {
        city: city,
    },
    callback:function(r) {
      if(r.message?.categories) {
        let top_section_num = 0;
        let bottom_section_num = 0;
        r.message.categories.forEach((category) => {
          const headerHtml = `<div class="section-wrapper section-header-wrapper sale-production-section-header">
                                <div class="section-heading">
                                  <span class="blue-title">${category.blue_title}</span>
                                  <span class="black-titl">${category.black_title}</span>
                                </div>
                                <a class="section-view-all-link" href="${category.view_all_link}">View all ></a>
                              </div>`;
          if(category.is_top && top_section_num < 3) {
            if(category.is_grey_background) {
              $(`#sale-top-category-section-${top_section_num}`).addClass("grey_bg_section");
            }
            $(`#sale-top-category-section-${top_section_num} .container`).prepend(headerHtml).append(`<div class="sale-loader" data-info="${category.category_name}"></div>`);
            load_recommended_category_products(category.category_name, `#sales-product-${top_section_num}`);
            top_section_num++;
          } else if (!category.is_top && bottom_section_num < 3){
            if(category.is_grey_background) {
              $(`#sale-top-category-section-b-${bottom_section_num}`).addClass("grey_bg_section");
            }
            $(`#sale-top-category-section-b-${bottom_section_num} .container`).prepend(headerHtml).append(`<div class="sale-loader" data-info="${category.category_name}"></div>`);
            load_recommended_category_products(category.category_name, `#sales-product-b-${bottom_section_num}`);
            bottom_section_num++;
          }
        })
      }
      
    }
  })
}

function get_category(data) {
    return [
        {
            title: data["callout_title_1"], 
            link: data["callout_link_1"], 
            desc: data["callout_desc_1"], 
            img: data["callout_image_1"]
        },
        {
            title: data["callout_title_2"], 
            link: data["callout_link_2"], 
            desc: data["callout_desc_2"], 
            img: data["callout_image_2"]
        },
        {
            title: data["callout_title_3"], 
            link: data["callout_link_3"], 
            desc: data["callout_desc_3"], 
            img: data["callout_image_3"]
        }    
    ]
}

function get_resource(data) {
    return [
        {
            title: data["resource_title_1"], 
            link: data["resource_link_1"],
            desc: data["resource_desc_1"], 
            img: data["resource_image_1"]
        },
        {
            title: data["resource_title_2"], 
            link: data["resource_link_2"], 
            desc: data["resource_desc_2"], 
            img: data["resource_image_2"]
        },
        {
            title: data["resource_title_3"], 
            link: data["resource_link_3"], 
            desc: data["resource_desc_3"], 
            img: data["resource_image_3"]
        },
        {
            title: data["resource_title_4"], 
            link: data["resource_link_4"], 
            desc: data["resource_desc_4"], 
            img: data["resource_image_4"]
        },
    ]
}

function load_homepage() {
    check_cookie_consent();

    // Create an Intersection Observer
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                if (img.dataset.src) {
                    img.style.backgroundImage = `url(${img.dataset.src})`;
                    img.removeAttribute('data-src');
                }
                observer.unobserve(img);
            }
        });
    }, {
        rootMargin: '50px 0px',
        threshold: 0.01
    });

    frappe.call({
        method: "bellamar_website.utils.api.get_homepage_data",
        args: {
            city: city
        },
        callback:function(r){
            if (r.message.length === 1) {
                explore_1_heading = r.message[0]["category_1"]
                explore_2_heading = r.message[0]["category_2"]
                explore_1_fields = r.message[0]["category_list_1"].split("|")
                explore_2_fields = r.message[0]["category_list_2"].split("|")
                callout_list = get_category(r.message[0])
                resource_list = get_resource(r.message[0])

                // Append callout cards
                callout_list.forEach((callout, i) => {
                    const calloutCard = $(`
                    <a href="${callout.link}" class="homepage-callout-card">
                        <div class="image card-${i + 1}" data-src="${callout.img}">
                        </div>
                        <div class="center-text">
                          <div class="center-text-subbox">
                            <div class="homepage-callout-heading">
                                ${callout.title}
                            </div>
                            <div class="homepage-callout-description">
                                ${callout.desc}
                            </div>
                            <div class="shop-now">
                                Shop Now
                            </div>
                          </div>
                        </div>
                    </a>
                    `);
                    callout_wrapper.append(calloutCard);
                    observer.observe(calloutCard.find(`.card-${i + 1}`)[0]);
                });

                // Append resource cards
                resource_list.forEach((resource, i) => {
                    const resourceCard = $(`
                    <a class="resource-card" href="${resource.link}">
                        <div class="resource-image" data-src="${resource.img}">
                        </div>
                        <div class="resource-title">
                            ${resource.title}
                        </div>
                        <div class="resource-desc">
                            ${resource.desc}
                        </div>
                        <div class="resource-arrow">
                        </div>
                    </a>
                    `);
                    resource_wrapper.append(resourceCard);
                    observer.observe(resourceCard.find('.resource-image')[0]);
                });
            }
            load_category_tree();
        }
    });
}

function check_cookie_consent() { 

    var consent = frappe.get_cookie('cookie_consent')

    if (consent == null || consent == "" || consent == undefined) {
        $('#cookie_directive_container').show();
    }

    $(".cookie-accept").click(function() {
        set_cookie_consent()
    });

    $(".cookie-close").click(function() {
        set_cookie_consent()
    });

}

function set_cookie_consent() {
    $('#cookie_directive_container').hide('slow');
    frappe.call({
        method: "bellamar_website.utils.utils.set_cookie",
        args: {
            cookie_name: "cookie_consent",
            value: 1,
            days_valid: 90,
        }
    })
}

function load_category_tree() {
    frappe.call({
        method: "bellamar_website.utils.api.get_all_nodes_itemgroup",
        args: {
            doctype: "Item Group",
            parent : "Biiibo Catalogue",
            tree_method: "bellamar_website.utils.api.get_children_itemgroup"
        },
        callback:function(r){
            if(r.message){
                var bc_index = 0
                for(var i = 0; i < r.message.length; i++) {
                    if( r.message[i].parent ===  "Biiibo Catalogue") {
                        bc_index = i;
                        break;
                    }
                }
                update_web_item_groups(r.message, bc_index);
                update_mob_item_groups(r.message, bc_index);
                var script1 = document.createElement('script');
                script1.src = "/assets/bellamar_website/js/category_menu.js?build=@v24.09.26";
                document.head.appendChild(script1);
                load_explore_content_slide('#explore-content-1');
                load_explore_content_slide('#explore-content-2');
            }
        }
    });
}


function load_owlCarousel(id){
  $(`${id} .owl-carousel`).owlCarousel({
      mouseDrag: false,
      loop: true,
      margin: 0,
      nav: true,
      dots: true,
      responsive: {
          0: {
              items: 1
          },
          600: {
              items: 2
          },
          1000: {
              items: 4
          },
          1400: {
              items: 6
          }
      }
  });
}


function load_recommended_category_products(product_group, element_id) {
  let options = {
    parent: $(`${element_id} div`),
    result: $(`${element_id} div`),
    products_template: 'templates/includes/products_as_grid_new.html',
    city,
    product_group,
    callback: function(r){
        load_owlCarousel(element_id);
    },
  };
  new bellamar.views.ProductList(options);
}

function update_web_item_groups(r, bc_index){
    var count = 0;
    var limit = 0

    category_tree_web_wrapper.append( `<li id="show-all-departments" class="view-all-items-link"><a href="/shop">All Departments</a></li>`);

    for (var key in r[bc_index].data) {
        var index = 0;
        for(var i = 0; i < r.length; i++) {
            if( r[i].parent ===  r[bc_index].data[key]['title']) {
                index = i;
                break;
            }
        }
        if(index){
            if (r[bc_index].data[key].value === explore_1_heading) {
                explore_1_wrapper.text("Explore " + explore_1_heading);
            }
            if (r[bc_index].data[key].value === explore_2_heading) {
                explore_2_wrapper.text("Explore " + explore_2_heading);
            }
            if(typeof limit === 'number'){
              const category_list_html = `
                <li class="category-has-children" id="`+ r[bc_index].data[key]['title'] +`" data-id="sub_sec-`+key+`">
                    <a href="/${r[bc_index].data[key]['route']}" class="">`+ r[bc_index].data[key]['value'] +`</a>
                </li>
              `;
              const category_list_col_3 = `
                  <ul class="dropdown-col-3-item" style="background-color: white!important;" data-id="sub_sec-`+key+`">
                        <div class="row">
                            <ul class="sub-menu-sec card-columns"  style="column-count: 2;" id="sub_sec-`+key+`" data-id="sub_sec-`+key+`"></ul>
                        </div>
                    </ul>
              `
                category_tree_web_wrapper.append( category_list_html );
                category_tree_col3_web_wrapper.append(category_list_col_3);
            }
            for(var j in r[index].data) {
                index_2 = 0;
                //find index and update child submenu
                for(var k = 0; k < r.length; k++) {
                    if( r[k].parent ===  r[index].data[j]['title']) {
                        index_2 = k;
                        break;
                    }
                }
                if (r[bc_index].data[key].value === explore_1_heading && explore_1_fields.includes(r[index].data[j]['value'])) {
                    explore_1_content_wrapper.append(`
                    <div class="explore_slide_card">
                      <a class="explore-category" href="/${r[index].data[j]['route']}">
                          <div class="explore-category-image" style="background-image: url(${r[index].data[j]['image']});">
                          </div>
                          <div class="explore-category-title">
                              <span>${r[index].data[j]['value']}</span>
                              <img class="explore-category-arrow-icon" src="/assets/bellamar_website/css/assets/right-arrow.png" alt="">
                          </div>
                      </a>
                    </div>
                    `)   
                }
                if (r[bc_index].data[key].value === explore_2_heading && explore_2_fields.includes(r[index].data[j]['value'])) {
                    explore_2_content_wrapper.append(`
                    <div class="explore_slide_card">
                      <a class="explore-category" href="/${r[index].data[j]['route']}">
                          <div class="explore-category-image" style="background-image: url(${r[index].data[j]['image']});">
                          </div>
                          <div class="explore-category-title">
                            <span>${r[index].data[j]['value']}</span>
                            <img class="explore-category-arrow-icon" src="/assets/bellamar_website/css/assets/right-arrow.png" alt="">
                          </div>
                      </a>
                    </div>
                    `)   
                }
                if (typeof limit === 'number') {
                    if(index_2){
                        $('#sub_sec-'+key).append( `
                            <li>
                                <a href="/`+r[index].data[j]['route']+`" class="title"><span>`+ r[index].data[j]['value'] +`</span></a>
                                <hr>
                                <ol id="sub_sec2-`+ count +`"></ol>
                            </li>
                        `);
                        for(var l in r[index_2].data) {

                            if (r[bc_index].data[key].value === explore_1_heading && explore_1_fields.includes(r[index_2].data[l]['value'])) {
                                explore_1_content_wrapper.append(`
                                <a class="explore-category" href="/${r[index_2].data[l]['route']}">
                                    <div class="explore-category-image" style="background-image: url(${r[index_2].data[l]['image']});">
                                    </div>
                                    <div>
                                        ${r[index_2].data[l]['value']} >
                                    </div>
                                </a>
                                `)   
                            }
                            if (r[bc_index].data[key].value === explore_2_heading && explore_2_fields.includes(r[index_2].data[l]['value'])) {
                                explore_2_content_wrapper.append(`
                                <a class="explore-category" href="/${r[index].data[j]['route']}">
                                    <div class="explore-category-image" style="background-image: url(${r[index_2].data[l]['image']});">
                                    </div>
                                    <div>
                                        ${r[index_2].data[l]['value']} >
                                    </div>
                                </a>
                                `)   
                            }

                            $('#sub_sec2-'+count).append( `
                            <li><a href="/`+r[index_2].data[l]['route']+`">`+ r[index_2].data[l]['value'] +`</a></li>
                            `);
                        }
                    }
                    else{
                        $('#sub_sec-'+key).append( `
                            <li><a href="/`+r[index].data[j]['route']+`" class="title"><span>`+ r[index].data[j]['value'] +`</span></a></li>
                        `);
                    }
                }
                count++;
            }
        }
        else{
            if (typeof limit === 'number') { 
                category_tree_web_wrapper.append( `
                    <li><a href="/`+r[bc_index].data[key]['route']+`" class="">`+ r[bc_index].data[key]['value'] +`</a></li>
                `);
            }
        }

        limit++;
    }
}
function update_mob_item_groups(r, bc_index){
    var count = 0;
    for (var key in r[bc_index].data) {
        var index = 0;
        for(var i = 0; i < r.length; i++) {
            if( r[i].parent ===  r[bc_index].data[key]['title']) {
                index = i;
                break;
            }
        }
        if(index){
            category_tree_mob_wrapper.append( `
                <li class="has-children">
                    <a href="javascript:void(0);">`+ r[bc_index].data[key]['value'] +`</a>
                    <ul class="cd-secondary-dropdown mobile-nested-ul is-hidden fade-out" id="mob_sub_sec-`+key+`">
                        <li class="go-back"><a href="javascript:void(0);">Back</a></li>
                        <li class="see-all"><a href="javascript:void(0);" class="main-cate">`+ r[bc_index].data[key]['value'] +`<hr></a></li>
                    </ul>
                </li>
            `);
            for(var j in r[index].data) {
                index_2 = 0;
                //find index and update child submenu
                 for(var k = 0; k < r.length; k++) {
                    if( r[k].parent ===  r[index].data[j]['title']) {
                        index_2 = k;
                        break;
                    }
                 }
                 if(index_2){
                    $('#mob_sub_sec-'+key).append( `
                        <li class="has-children">
                            <a href="/`+r[index].data[j]['route']+`">`+ r[index].data[j]['value'] +`</a>
                            <ul id="mob_sub_sec2-`+ count +`" class="is-hidden mobile-nested-ul ">
                                <li class="go-back"><a href="javascript:void(0);">Back</a></li>
                                 <li class="see-all"><a href="javascript:void(0);" class="main-cate">`+ r[index].data[j]['value'] +`<hr></a></li>
                            </ul>
                        </li>
                     `);
                    for(var l in r[index_2].data) {
                         $('#mob_sub_sec2-'+count).append( `
                            <li class="see-all"><a href="/`+r[index_2].data[l]['route']+`">`+ r[index_2].data[l]['value'] +`</a></li>
                         `);
                    }
                }
                else{
                    $('#mob_sub_sec-'+key).append( `
                        <li><a href="/`+r[index].data[j]['route']+`">`+ r[index].data[j]['value'] +`</a></li>
                    `);
                }
                count++;
            }
        }
        else{
            category_tree_mob_wrapper.append( `
                <li><a href="/`+r[bc_index].data[key]['route']+`" class="">`+ r[bc_index].data[key]['value'] +`</a></li>
            `);
        }
    }
}

function load_brand_carousel(){
    $('.brand-carousel').owlCarousel({
        mouseDrag: false,
        loop: true,
        margin: 0,
        nav: true,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 4
            },
            1400: {
                items: 6
            }
        }
    });
}



function load_explore_content_slide(selector){
  $(selector).owlCarousel({
      nav: true,
      mouseDrag: true,
      loop: true,
      margin: 0,
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 3
        },
        1400: {
            items: 4
        }
      }
  });
}

function observeDisabledClass(targetSelector) {
  var target = document.querySelector(targetSelector);
  
  if (!target) return;
  
  var config = { attributes: true, childList: true, subtree: true };

  var callback = function(mutationsList, observer) {
    mutationsList.forEach(function(mutation) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class' && mutation.target.classList.contains('disabled')) {
        mutation.target.classList.remove('disabled');
      }
    });
  };

  var observer = new MutationObserver(callback);
  observer.observe(target, config);
}

document.addEventListener("DOMContentLoaded", function() {
  // Some reason, owlCarousel keeps adding `disable` to nav so I've added it to remove disable class
  observeDisabledClass('#explore-content-1');
  observeDisabledClass('#explore-content-2');
});


function BlogStyleBulletLists() {
  const blogContent = document.querySelector('.blog-content');
  if (!blogContent) return;

  const olElements = blogContent.querySelectorAll('ol');
  if (olElements.length === 0) return;

  // Add bullet styles
  const style = document.createElement('style');
  style.textContent = `
    .blog-content .forced-bullets {
      list-style-type: disc !important;
      padding-left: 20px !important;
    }
    .blog-content .forced-bullets li {
      display: list-item !important;
      list-style-type: disc !important;
    }
  `;
  document.head.appendChild(style);

  // Process ordered lists
  olElements.forEach(ol => {
    const listItems = Array.from(ol.querySelectorAll('li'));
    const allBullets = listItems.every(li => li.dataset.list === 'bullet');

    if (allBullets) {
      // Convert to unordered list
      const ul = document.createElement('ul');
      ul.className = ol.className + ' forced-bullets';
      
      listItems.forEach(li => ul.appendChild(li.cloneNode(true)));
      ol.parentNode.replaceChild(ul, ol);
    } else {
      // Keep as ordered list but add forced-bullets class
      ol.classList.add('forced-bullets');
    }
  });
}

if (window.location.pathname.includes('/blog/')) {
  // [NOTE] Blog content is managed by the Marketing team through the ERP system, 
  // which currently has an unresolved issue with handling rich text formatting (refer to: https://github.com/frappe/frappe/pull/19531).
  // As a temporary workaround, we are implementing a custom JavaScript solution to ensure proper bullet list styling 
  // until the issue is resolved in a future Frappe update.
  document.addEventListener('DOMContentLoaded', BlogStyleBulletLists);
}
